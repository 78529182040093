// jQuery(window).on('load', function () {
$(document).on('turbolinks:load', function() {
  $loadingOverlay = $('#loading_overlay');
  $feedbackContainer = $('#feedback_container');
  $toggleLoadingOverlay = function() {
    $loadingOverlay.fadeToggle(200);
  }
  $toggleSuccess = function(message) {
    console.log('toggling success');
    $feedbackContainer.removeClass('error').addClass('success');
    $feedbackContainer.text(message);
    $feedbackContainer.fadeIn(200).delay(3000).fadeOut(400);
  }
  $toggleError = function(message) {
    console.log('toggling error');
    $feedbackContainer.removeClass('success').addClass('error');
    $feedbackContainer.text(message);
    $feedbackContainer.fadeIn(200).delay(3000).fadeOut(400);
  }
  $('form').submit(function() {
    $toggleLoadingOverlay();
  });

});
