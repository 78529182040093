import consumer from "./consumer"

document.addEventListener('turbolinks:load', () => {
  if ($('body').attr('id') == 'microsites') {
    consumer.subscriptions.create("MicrositesChannel", {
      connected() {
        console.log('connected');
      },
      disconnected() {
        // Called when the subscription has been terminated by the server
      },
      received(data) {
        if (data.status == 'generated') {
          $('#event_' + data.usiEventId).text(data.eventName);
        } else {
          console.log('data received');
          console.log('Sent By: ' + data.sent_by);
        }
        // console.log(data);
        // Called when there's incoming data on the websocket for this channel
      }
    });
  }
});